import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import axios from 'axios';

const OnlineSchoolForm = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [focusedInput, setFocusedInput] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await axios.post('/api/v1/contact', data);
      setSubmitResult({ success: true, message: '消息已成功发送！' });
      reset();
    } catch (error) {
      setSubmitResult({ success: false, message: '发送失败，请稍后重试。' });
    }
    setIsSubmitting(false);
  };
  return (
    <>
      <div className="rbt-contact-form contact-form-style-1">
        <h3 className="title">获取免费 BestPath 在线课程</h3>
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className={`form-group ${focusedInput === "name" ? "focused" : ""}`}>
            <input
              {...register('name', { required: '请输入您的姓名' })}
              type="text"
              placeholder="姓名"
              onFocus={() => setFocusedInput("name")}
              onBlur={() => setFocusedInput(null)}
            />
            <span className="focus-border"></span>
            {errors.con_name && <span className="error">{errors.con_name.message}</span>}
          </div>
          <div className={`form-group ${focusedInput === "email" ? "focused" : ""}`}>
            <input
              {...register('email', {
                required: '请输入您的邮箱',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: '请输入有效的邮箱地址'
                }
              })}
              type="email"
              placeholder="邮箱"
              onFocus={() => setFocusedInput("email")}
              onBlur={() => setFocusedInput(null)}
            />
            <span className="focus-border"></span>
            {errors.con_email && <span className="error">{errors.con_email.message}</span>}
          </div>
          <div className={`form-group ${focusedInput === "message" ? "focused" : ""}`}>
            <textarea
              {...register('message', { required: '请输入您的消息' })}
              placeholder="消息"
              onFocus={() => setFocusedInput("message")}
              onBlur={() => setFocusedInput(null)}
            ></textarea>
            <span className="focus-border"></span>
            {errors.message && <span className="error">{errors.message.message}</span>}
          </div>
          <div className="form-submit-group">
            <button
              type="submit"
              className="rbt-btn btn-md btn-gradient hover-icon-reverse radius-round w-100"
              disabled={isSubmitting}
            >
              <span className="icon-reverse-wrapper">
                <span className="btn-text">{isSubmitting ? '提交中...' : '立即提交'}</span>
                <span className="btn-icon">
                  <i className="feather-arrow-right"></i>
                </span>
                <span className="btn-icon">
                  <i className="feather-arrow-right"></i>
                </span>
              </span>
            </button>
          </div>
          {submitResult && (
            <div className={`submit-result ${submitResult.success ? 'success' : 'error'}`}>
              {submitResult.message}
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default OnlineSchoolForm;
