import PageHead from "../Head";
import { Provider } from "react-redux";
import Store from "@/redux/store";
import BackToTop from "../backToTop";

import Context from "@/context/Context";
import MobileMenu from "@/components/Header/MobileMenu";
import Cart from "@/components/Header/Offcanvas/Cart";

import HeaderStyleEleven from "@/components/Header/HeaderStyle-Eleven";
import OnlineSchool from "@/components/03-online-school/OnlineSchool";
import FooterOne from "@/components/Footer/Footer-One";

const OnlineSchoolPage = () => {
  return (
    <>
      <PageHead title="" />
      <PageHead 
        title="Bestpath - 您的个性化AI教育解决方案"
        description="探索Bestpath教育的革新性在线课程体验。利用AI技术进行智能选课,享受一对一专业导师辅导,获取个性化论文创作指导。我们的服务包括AI选课、实时互动授课、论文写作辅导和Turnitin查重,助力国际学生实现学术成功。"
        keywords="AI选课,在线课程,一对一导师,个性化学习,论文指导,Turnitin查重,国际学生,学术辅导,智能排课,在线教育"
       />

       
      <Provider store={Store}>
        <Context>
          <MobileMenu />
          <HeaderStyleEleven />
          {/* <Cart /> */}

          <OnlineSchool />

          <BackToTop />
          <FooterOne />
        </Context>
      </Provider>
    </>
  );
};

export default OnlineSchoolPage;
